/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { createRoot } from 'react-dom/client';
import CookieAlert from './cookies/CookieAlert';

const setup = () => {
  const hasAcceptedCookies = document.cookie.split(';').filter(cookie => cookie.includes('accepted_cookies=true')).length;

  if (!hasAcceptedCookies) {
    const container = document.createElement('div');
    const root = createRoot(container);

    container.setAttribute('id', 'cookieAlert');
    document.body.prepend(container);

    root.render(<CookieAlert />);
  }
};

document.addEventListener('DOMContentLoaded', setup);

export default setup;
